<template>
  <span :class="badgeClasses">
    <label>{{ label }}</label>
  </span>
</template>

<script>
import { reactive, computed } from "vue";
import { badgeProperties } from "./Badge.js";

export default {
  name: "PluginBadge",
  props: {
    type: {
      type: String,
      default: "warning",
      validator: (value) => {
        return badgeProperties.types.all.includes(value);
      },
    },
    label: {
      default: "To Do",
    },
  },
  setup(props) {
    props = reactive(props);
    return {
      badgeClasses: computed(() => ({
        "revue-badge": true,
        [`revue-badge--${props.type}`]: true,
      })),
      style: computed(() => ({
        type: props.type,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global";
@import "@/styles/_variables.scss";
@import "./Badge.scss";
</style>
