<template>
  <div :id="id" :class="computedClass" role="alert">
    <component :is="icon" :size="24" v-if="icon"></component>
    <div class="alert-container">
      <p v-if="title" class="alert-heading">
        <strong>{{ title }}</strong>
      </p>
      <PluginButtonClose
        v-if="props.closable"
        style="padding: 0"
        data-bs-dismiss="alert"
        @click="handleClose"
      ></PluginButtonClose>
      <div v-if="$slots.body || htmlBody || body" class="alert-body">
        <template v-if="!$slots.body">
          <p v-html="htmlBody" v-if="htmlBody"></p>
          <p v-else-if="body">{{ body }}</p>
        </template>
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap";

export default {
  name: "PluginAlert",
};
</script>

<script setup>
import { computed } from "vue";
import { colorVariants } from "./Alert.js";
import PluginButtonClose from "../ButtonClose/ButtonClose.vue";
const props = defineProps({
  id: String,
  title: String,
  body: String,
  htmlBody: String,
  type: {
    type: String,
    default: "light",
    validator: (value) => {
      return colorVariants.includes(value);
    },
  },
  icon: { type: Object, default: null },
  closable: { type: Boolean, default: true },
});

const emit = defineEmits(["onClose"]);

function handleClose() {
  emit("onClose", props.id);
}

const computedClass = computed(
  () => `alert alert--${props.type} d-flex align-items-start`
);
</script>

<style lang="scss" scoped>
@import "@/styles/global";
@import "./Alert";
</style>
