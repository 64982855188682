<template>
  <button type="button" :class="classes" @click="click">
    <div class="button-body">
      <component :is="icon" class="icon" />
      <strong v-if="label">{{ label }}</strong>
      <span v-if="dropdown" class="caret"></span>
    </div>
  </button>
</template>

<script>
import { reactive, computed } from "vue";
import { buttonProperties } from "./Button.js";

export default {
  name: "PluginButton",
  components: {},
  props: {
    label: { type: String, required: false },
    type: {
      type: String,
      validator: (value) => {
        return buttonProperties.types.all.includes(value);
      },
      default: "primary",
    },
    size: {
      type: String,
      validator: (value) => {
        return buttonProperties.sizes.all.includes(value);
      },
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdown: { type: Boolean, default: false },
    icon: { type: Object, default: null },
  },

  emits: ["click"],

  setup(props, { emit }) {
    props = reactive(props);
    return {
      classes: computed(() => ({
        button: true,
        [`button--${props.size}`]: true,
        [`button--${props.type}`]: true,
        [`button--dropdown`]: props.dropdown,
        [`button--icon`]: !!props.icon,
        [`button--icon-only`]: !!props.icon && !props.label,
        disabled: props.disabled,
      })),
      click() {
        emit("click");
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global";
@import "./Button.scss";
</style>
