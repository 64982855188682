<template>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :stroke="color" stroke-width="2" d="M7 10L9 12L13.5 7"></path>
         </svg>
  </template>
  
  <script>
    export default {
      name: 'TickIcon',
      props: {
        color: String,
      },
    }
  </script>