<template>
  <PluginButton
    type="secondary"
    :icon="CloseIcon"
    @click="click"
    class="button-close"
  >
  </PluginButton>
</template>

<script setup>
import { defineEmits } from "vue";
import PluginButton from "../Button/Button.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";

const emit = defineEmits(["click"]);

const click = () => {
  emit("click");
};
</script>

<style lang="scss" scoped>
@import "@/styles/global";
@import "./ButtonClose.scss";
</style>
