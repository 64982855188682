export const buttonProperties = {
  types: {
    all: ["primary", "secondary"],
    default: "primary",
    description: "Button type",
  },
  sizes: {
    all: ["default", "full"],
    default: "default",
    description: "Button size: default or full",
  },
};
