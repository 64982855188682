<template>
  <span :class="bulletClasses" :style="style">
    <label>{{ label }}</label>
    <tick-icon
      v-if="type === 'check'"
      :color="state === 'dark' ? 'white' : '#212529'"
    />
  </span>
</template>

<script>
import { reactive, computed } from "vue";
import { bulletProperties } from "./Bullet.js";
import TickIcon from "@/icons/TickIcon.vue";

export default {
  name: "PluginBullet",
  components: {
    TickIcon,
  },
  props: {
    label: {
      type: String,
      default: "A",
    },
    type: {
      type: String,
      default: "letter",
      validator: (value) => {
        return bulletProperties.types.all.includes(value);
      },
    },
    state: {
      type: String,
      default: "success",
      validator: (value) => {
        return bulletProperties.states.all.includes(value);
      },
    },
  },
  setup(props) {
    props = reactive(props);
    return {
      bulletClasses: computed(() => ({
        "revue-bullet": true,
        [`revue-bullet--${props.type}-${props.state}`]: true,
      })),
      style: computed(() => ({
        type: props.type,
      })),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global";
@import "@/styles/_variables.scss";
@import "./Bullet.scss";
</style>
