<!-- PluginLoadingSpinner.vue -->
<template>
  <transition name="fade">
    <div v-if="show" class="overlay-spinner">
      <n-spin size="large" />
    </div>
  </transition>
</template>

<script>
import { NSpin } from "naive-ui";

export default {
  name: "PluginLoadingSpinner",
  components: {
    NSpin,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.overlay-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
